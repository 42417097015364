import { Button, Dropdown, Grid, MenuProps } from 'antd';
import React, { FC, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BugOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useUser } from '../user/userContext';
import { useToken } from '../auth/token';
import MenuIcon from '../resources/img/menu.svg';
import SafeLifeLogo from '../resources/img/safeLifeLogo.svg';
import DownArrow from '../resources/img/downArrow.svg';
import Gravatar from '../user/components/Gravatar';
import SubsidiaryCompanyPicker from '../subsidiaryCompany/components/SubsidiaryCompanyPicker';
import { userHasPermission, userHasPermissions } from '../user/userUtils';
import { Permission } from '../user/types/Permission';

import NavigationDrawer from './NavigationDrawer';

const MAX_ITEMS_BEFORE_OVERFLOW = 4;

const BUG_REPORT_ENGLISH_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdhs-LYCOLmc_a2i8fGzzUskApAngXtmBD6ab3nLalMO1Imog/viewform';
const BUG_REPORT_SWEDISH_URL = 'https://forms.gle/828NvpBmqbYNtM8b6';

const Navbar: FC = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const [, setToken] = useToken();
  const [currentUser] = useUser();
  const navigate = useNavigate();
  const breakpoint = Grid.useBreakpoint();

  const { t } = useTranslation();
  const isSelectedTab = (path: string) => pathname.includes(path);

  const logout = () => {
    setToken(null);
  };

  const openBugReport = () => {
    const bugReportUrl =
      currentUser?.preferredLanguage?.code === 'sv'
        ? BUG_REPORT_SWEDISH_URL
        : BUG_REPORT_ENGLISH_URL;

    const win = window.open(bugReportUrl, '_blank', 'noreferrer noopener');
    win?.focus();
  };

  const goToNewOrderPage = () => {
    if (location.pathname === '/ny-bestallning') return;
    navigate('/ny-bestallning');
  };

  const MenuItem: React.FC<{ label: string; to: string }> = ({ label, to }) => (
    <Link to={to} className="nav-bar-menu-item">
      <div className="relative">
        <span className="text-sm font-medium">{label}</span>
        {isSelectedTab(to) && (
          <div className="mt-2 absolute border-solid border-white border rounded-full mx-2 left-0 right-0"></div>
        )}
      </div>
    </Link>
  );

  const allMenuItems = [
    userHasPermission(currentUser, Permission.DASHBOARD_READ) && {
      to: '/start',
      label: t('components.Navbar.start'),
    },
    userHasPermission(currentUser, Permission.BOOKING_ORDER_READ_LIST) && {
      to: '/bestallningar',
      label: t('components.Navbar.orders'),
    },
    userHasPermission(currentUser, Permission.COURSE_INSTANCE_READ_LIST) && {
      to: '/kurstillfallen',
      label: t('components.Navbar.courseInstances'),
    },
    userHasPermissions(
      currentUser,
      Permission.USER_READ_LIST,
      Permission.USER_READ_GLOBAL,
    ) && { to: '/anvandare', label: t('common.users') },
    userHasPermission(currentUser, Permission.INSIGHTS_READ) && {
      to: '/insikter-v2',
      label: t('components.Navbar.insights'),
    },
    userHasPermission(currentUser, Permission.COURSE_READ) && {
      to: `/kurser`,
      label: t('common.courses'),
    },
    userHasPermission(currentUser, Permission.INVOICE_RECIPIENT_READ_LIST) && {
      to: '/fakturamottagare',
      label: t('common.invoiceRecipient'),
    },
    userHasPermission(currentUser, Permission.SUBSIDIARY_COMPANY_READ_LIST) && {
      to: '/foretag',
      label: t('components.Navbar.subsidiaryCompanies'),
    },
    userHasPermission(currentUser, Permission.MARKET_READ_LIST) && {
      to: '/markets',
      label: t('components.Navbar.markets'),
    },
    userHasPermission(currentUser, Permission.CERTIFICATE_LANGUAGE_UPDATE) && {
      to: '/certificateLanguages',
      label: t('components.Navbar.certificateLanguages'),
    },
    userHasPermission(currentUser, Permission.CERTIFICATE_IMAGE_UPDATE) && {
      to: '/certificateImages',
      label: t('components.Navbar.certificateImages'),
    },
    userHasPermission(
      currentUser,
      Permission.SUBSIDIARY_COMPANY_GOAL_READ_LIST,
    ) && {
      to: '/salesTargets',
      label: t('components.Navbar.salesTargets'),
    },
  ].filter(Boolean) as { to: string; label: string }[];

  const menuItems = allMenuItems
    .slice(0, MAX_ITEMS_BEFORE_OVERFLOW)
    .map((props) => <MenuItem key={props.to} {...props} />);

  const moreMenuItems: MenuProps['items'] = allMenuItems
    .slice(MAX_ITEMS_BEFORE_OVERFLOW)
    .map(({ to, label }) => ({
      key: to,
      label: (
        <Link
          to={{
            pathname: to,
          }}>
          {label}
        </Link>
      ),
    }));

  const userMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          to={{
            pathname: `/anvandare/${currentUser?.id}`,
          }}>
          <UserOutlined className="pr-1" /> {t('components.Navbar.myPage')}
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={openBugReport}>
          <BugOutlined className="pr-1" /> {t('components.Navbar.bugReport')}
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div onClick={logout}>
          <LogoutOutlined className="pr-1" /> {t('components.Navbar.logout')}
        </div>
      ),
    },
  ];

  const currentMenuItem = allMenuItems.find(({ to }) =>
    pathname.startsWith(to),
  );

  return (
    <>
      <nav className="grid grid-cols-3 bg-safeLifeDark shadow-md px-2 lg:px-8 h-18 z-10">
        <div className="flex mr-auto items-center">
          <img
            src={SafeLifeLogo}
            className={`pb-1 ${breakpoint.lg ? '' : 'pl-2'}`}
          />
          {breakpoint.lg && (
            <div className="flex">
              {userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) ? (
                <div>
                  <SubsidiaryCompanyPicker />
                </div>
              ) : (
                <div className="px-3">
                  <span className="text-white text-base font-medium">
                    {currentUser?.preferredSubsidiaryCompany?.name}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {breakpoint.lg && (
          <div className="flex items-center justify-center gap-4">
            {menuItems}
            {!!moreMenuItems.length && (
              <Dropdown
                className="nav-bar-menu-item"
                menu={{ items: moreMenuItems }}
                trigger={['click']}>
                <div className="flex items-center text-sm font-medium">
                  {t('components.Navbar.more')}
                  <img src={DownArrow} className="pl-1 pt-0.5" />
                </div>
              </Dropdown>
            )}
          </div>
        )}
        {!breakpoint.lg && (
          <div className="flex items-center justify-center">
            {currentMenuItem && (
              <MenuItem label={currentMenuItem.label} to={currentMenuItem.to} />
            )}
          </div>
        )}
        {breakpoint.lg && (
          <div className="flex items-center ml-auto">
            {userHasPermission(
              currentUser,
              Permission.BOOKING_ORDER_CREATE,
            ) && (
              <Button
                shape="round"
                className="h-10 bg-gray-100 border-0 text-black hover:bg-white duration-200"
                onClick={goToNewOrderPage}>
                <span className="text-safeLifeDark">
                  {t('components.Navbar.newOrder')}
                </span>
              </Button>
            )}
            <Dropdown
              className="cursor-pointer hover:bg-primary-600 duration-200"
              menu={{ items: userMenuItems }}
              trigger={['click']}>
              <div className="flex items-center border-solid border border-gray-400 rounded-full ml-4 h-10">
                <img src={MenuIcon} className="ml-3 mr-2" />
                <Gravatar
                  size="small"
                  className="mr-1"
                  email={currentUser?.email}
                />
              </div>
            </Dropdown>
          </div>
        )}

        {!breakpoint.lg && (
          <div className="flex items-center justify-end pr-2">
            <Button
              icon={<MenuOutlined />}
              size="large"
              onClick={() => setDrawerIsOpen(true)}
            />
          </div>
        )}
      </nav>
      <NavigationDrawer
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        menuItems={allMenuItems}
        openBugReport={openBugReport}
        logout={logout}
      />
    </>
  );
};

export default Navbar;
