import React, { FC } from 'react';
import {
  CourseInstanceStatus,
  CourseInstanceStatusData,
  CourseInstanceStatusYMode,
} from '../types/CourseInstanceStatus';
import { ResponsiveBar } from '@nivo/bar';
import { Card, Row } from 'antd';
import { translateCourseInstanceStatus } from '../utils/translate';
import { useInsights } from '../../context/insights';
import { renderLightTick } from '../utils/axis';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { BucketSizeOption } from '../types/InsightsFilters';

type CourseInstanceStatusStackProps = {
  data?: CourseInstanceStatusData;
};

export const CourseInstanceStatusStack: FC<CourseInstanceStatusStackProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const {
    subsidiaryCompanyIds,
    startDate,
    endDate,
    statusYMode: yMode,
    selectedStatus,
    setSelectedStatus,
    goals,
    statusBucketSize,
  } = useInsights();

  if (data === undefined) {
    return null;
  }

  const total =
    data.graphs
      .find((g) => g.id === CourseInstanceStatus.TOTAL)
      ?.data.reduce((prev, curr) => prev + curr.y, 0) ?? 0;

  const stackData = [
    {
      [CourseInstanceStatus.PRELIMINARY]:
        data.graphs
          .find((g) => g.id === CourseInstanceStatus.PRELIMINARY)
          ?.data.reduce((prev, curr) => prev + curr.y, 0) ?? 0,

      [CourseInstanceStatus.CONFIRMED]:
        data.graphs
          .find((g) => g.id === CourseInstanceStatus.CONFIRMED)
          ?.data.reduce((prev, curr) => prev + curr.y, 0) ?? 0,

      [CourseInstanceStatus.INVOICED]:
        data.graphs
          .find((g) => g.id === CourseInstanceStatus.INVOICED)
          ?.data.reduce((prev, curr) => prev + curr.y, 0) ?? 0,

      [CourseInstanceStatus.CANCELED]:
        data.graphs
          .find((g) => g.id === CourseInstanceStatus.CANCELED)
          ?.data.reduce((prev, curr) => prev + curr.y, 0) ?? 0,
    },
  ];

  const showRevenue = yMode === CourseInstanceStatusYMode.REVENUE;

  const formatter = Intl.NumberFormat('en', {
    maximumSignificantDigits: 2,
    notation: 'compact',
  });

  const sumUpGoalsOverRange = (): number => {
    let sum = 0;
    let curr = dayjs(startDate);
    const end = endDate.add(1, 'day');

    while (curr.isBefore(end)) {
      const goal = goals?.find((g) => g.activeMonth === curr.format('YYYY-MM'));

      if (goal) {
        const val = showRevenue
          ? goal.goalRevenue
          : goal.goalNumberOfCourseInstances;
        sum += (val ?? 0) / curr.daysInMonth();
      }

      curr = curr.add(1, 'day');
    }

    return sum;
  };

  const goal =
    subsidiaryCompanyIds?.length === 1 &&
    statusBucketSize === BucketSizeOption.Monthly
      ? sumUpGoalsOverRange()
      : undefined;

  return (
    <ResponsiveBar
      data={stackData}
      keys={Object.values(CourseInstanceStatus).filter(
        (e) => e !== CourseInstanceStatus.TOTAL,
      )}
      margin={{ top: 30, right: 30, bottom: 30, left: 36 }}
      innerPadding={3}
      valueScale={{ type: 'linear' }}
      borderRadius={5}
      colors={data.graphs
        .filter((g) => g.id !== CourseInstanceStatus.TOTAL)
        .map((g) =>
          !selectedStatus || selectedStatus === g.id ? g.color : `${g.color}44`,
        )}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        renderTick: (props) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderLightTick(props, (value: any) => {
            if (showRevenue) {
              return formatter.format(value);
            } else if (value % 1 === 0) {
              return value;
            }
          }),
      }}
      axisBottom={null}
      animate={false}
      enableLabel={false}
      tooltip={({ id, color, value }) => (
        <Card className="shadow" bodyStyle={{ padding: '12px' }}>
          <Row className="text-sm items-center gap-2">
            <div
              className="h-3 w-3 rounded-full"
              style={{ backgroundColor: color }}
            />
            <span>
              {translateCourseInstanceStatus(id as CourseInstanceStatus)}
            </span>
            <span className="ml-auto font-semibold">
              {Math.round((value * 100) / total)}%
            </span>
          </Row>
        </Card>
      )}
      onMouseEnter={(e) => setSelectedStatus(e.id as CourseInstanceStatus)}
      onMouseLeave={() => setSelectedStatus(null)}
      {...(goal && {
        maxValue: Math.max(Math.ceil(goal), total),
        markers: [
          {
            axis: 'y',
            value: goal,
            legend: t('views.InsightsV2.goalLabel'),
            lineStyle: {
              stroke: 'gray',
              strokeDasharray: '6, 4',
              strokeWidth: 2,
            },
            textStyle: {
              fill: 'gray',
            },
            legendOrientation: 'vertical',
            legendPosition: 'right',
          },
        ],
      })}
    />
  );
};
