import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker, Form, Input, Modal, Select } from 'antd';

import Course from '../types/Course';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { UserRole } from '../../user/types/User';
import UserAPI from '../../user/UserAPI';
import { CertificateTemplate } from '../../certificate/types/Certificate';
import CertificateAPI from '../../certificate/CertificateAPI';
import dayjs from 'dayjs';

type CertificatePreviewModalParams = {
  course: Course;
  certificateTemplate: CertificateTemplate;
  onClose?: () => void;
};

const CertificatePreviewModal: React.FC<CertificatePreviewModalParams> = ({
  course,
  certificateTemplate,
  onClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  const instructorsQueryKey = ['getInstructorsQuery', course.id];

  const instructorsQuery = useQuery({
    queryKey: instructorsQueryKey,
    queryFn: () => {
      return UserAPI.getUsers({ roles: [UserRole.Instructor] });
    },
  });

  const certificatePreviewQuery = useQuery({
    queryKey: ['getCertificatePreviewQueryKey'],
    enabled: false,
    staleTime: 0,
    queryFn: () =>
      CertificateAPI.getCertificatePreview(
        course.id,
        form.getFieldValue('languageId'),
        t('components.CertificatePreviewModal.fileName', {
          courseName: course.name,
        }),
        {
          attendeeName: form.getFieldValue('attendeeName'),
          location: form.getFieldValue('location'),
          classDate: dayjs(form.getFieldValue('classDate')).format(
            'YYYY-MM-DD',
          ),
          instructorId: form.getFieldValue('instructorId'),
        },
      ),
  });

  const certificateLanguages = useMemo(() => {
    return certificateTemplate.languageTextContents.map(
      (ltc) => ltc.certificateLanguage,
    );
  }, [certificateTemplate]);

  const onSubmit = useCallback(() => {
    certificatePreviewQuery.refetch();
  }, [certificatePreviewQuery]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  useEffect(() => {
    form.setFieldValue('classDate', dayjs());
  }, [form]);

  return (
    <>
      <Modal
        maskClosable={false}
        width={600}
        key="certificate-preview-modal"
        title={
          <div>
            <h3>
              {t('components.CertificatePreviewModal.title', {
                courseName: course.name,
              })}
            </h3>
            <div className="font-normal text-gray-700 -mt-2">{course.name}</div>
          </div>
        }
        open
        onCancel={onClose}
        okText={t('components.CertificatePreviewModal.download')}
        onOk={onSubmit}
        okButtonProps={{
          loading: certificatePreviewQuery.isLoading,
          disabled: !submittable,
        }}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            name="attendeeName"
            label={t('components.CertificatePreviewModal.attendeeName')}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="location"
            label={t('components.CertificatePreviewModal.location')}
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="instructorId"
            label={t('components.CertificatePreviewModal.instructor')}
            rules={[{ required: true }]}>
            <Select
              showSearch
              loading={instructorsQuery.isLoading}
              optionFilterProp="children"
              placeholder={t(
                'components.CertificatePreviewModal.selectInstructor',
              )}
              filterOption={(input, option) =>
                option?.children
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase()) ?? false
              }>
              {instructorsQuery.data?.data.map((instructor) => (
                <Select.Option
                  key={instructor.id}
                  value={instructor.id}
                  label={instructor.name}>
                  {instructor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="classDate"
            label={t('components.CertificatePreviewModal.classDate')}
            rules={[{ required: true }]}>
            <DatePicker allowClear={false} />
          </Form.Item>
          <Form.Item
            name="languageId"
            label={t('components.CertificatePreviewModal.language')}
            rules={[{ required: true }]}>
            <Select
              placeholder={t(
                'components.CertificatePreviewModal.selectLanguage',
              )}>
              {certificateLanguages.map((cl) => (
                <Select.Option key={cl.id} value={cl.id}>
                  {cl.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CertificatePreviewModal;
