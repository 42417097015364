import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Button,
  Col,
  DatePicker,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useInsights } from '../../context/insights';
import {
  BucketSizeOption,
  PresetIntervalOption,
} from '../types/InsightsFilters';
import { getMarkets } from '../../market/MarketAPI';
import Market from '../../market/types/Market';
import { FilterOutlined } from '@ant-design/icons';
import { useUser } from '../../user/userContext';
import { CourseInstanceStatusYMode } from '../types/CourseInstanceStatus';
import BucketSizePicker from './BucketSizePicker';

type InsightsFiltersProps = {
  toggleShowExtendedFilters: () => void;
  showExtendedFilters: boolean;
};

const InsightsFilters: React.FC<InsightsFiltersProps> = ({
  toggleShowExtendedFilters,
  showExtendedFilters,
}) => {
  const { t } = useTranslation();
  const {
    market,
    setMarket,
    subsidiaryCompanyIds,
    setSubsidiaryCompanyIds,
    clientCompanyIds,
    instructorIds,
    courseIds,
    responsibleBookerIds,
    invoiceRecipientIds,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setStatusYMode,
    statusBucketSize,
    setDatesAdjustedToBucketSize,
  } = useInsights();
  const [currentUser] = useUser();

  const [interval, setInterval] = useState<PresetIntervalOption | undefined>(
    PresetIntervalOption.Monthly,
  );
  const [markets, setMarkets] = useState<Market[]>();
  const [loadingMarkets, setLoadingMarkets] = useState(false);

  const fetchMarkets = useCallback(async () => {
    setLoadingMarkets(true);
    try {
      const { data } = await getMarkets();
      setMarkets(data);

      const currentUserMarketId = currentUser?.market.id;
      const currentUserMarket = data.find((m) => m.id === currentUserMarketId);

      if (currentUserMarket) {
        setMarket(currentUserMarket);
      }

      if (currentUser?.preferredSubsidiaryCompany?.id) {
        setSubsidiaryCompanyIds([currentUser.preferredSubsidiaryCompany.id]);
        setStatusYMode(CourseInstanceStatusYMode.REVENUE);
      }
    } finally {
      setLoadingMarkets(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMarkets();
  }, [fetchMarkets]);

  const presetIntervalOptions = Object.values(PresetIntervalOption).map(
    (io) => ({
      value: io,
      label: t(`components.InsightsFilters.IntervalOptions.${io}`),
    }),
  );

  const numberOfFiltersSelected = [
    subsidiaryCompanyIds,
    clientCompanyIds,
    instructorIds,
    courseIds,
    responsibleBookerIds,
    invoiceRecipientIds,
  ].filter((el) => (el as unknown as number[])?.length > 0).length;

  const highlightFilterButton =
    numberOfFiltersSelected > 0 || showExtendedFilters;

  const datePickerMode = useMemo(() => {
    switch (statusBucketSize) {
      case BucketSizeOption.Daily:
        return 'date';
      case BucketSizeOption.Weekly:
        return 'week';
      case BucketSizeOption.Monthly:
        return 'month';
    }
  }, [statusBucketSize]);

  const datePickerOnChange = (
    values: [Dayjs | null | undefined, Dayjs | null | undefined],
  ) => {
    setInterval(undefined);
    if (!values || !values[0] || !values[1]) {
      setStartDate(dayjs().subtract(3, 'month'));
      setEndDate(dayjs());
      return;
    }

    setDatesAdjustedToBucketSize(values[0], values[1], statusBucketSize);
  };

  const onIntervalChange = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      setInterval(value);

      switch (value) {
        case PresetIntervalOption.Yearly: {
          setDatesAdjustedToBucketSize(
            dayjs().subtract(11, 'month'),
            dayjs(),
            BucketSizeOption.Monthly,
          );
          break;
        }
        case PresetIntervalOption.Quaterly: {
          setDatesAdjustedToBucketSize(
            dayjs().subtract(2, 'month'),
            dayjs(),
            BucketSizeOption.Monthly,
          );
          break;
        }
        case PresetIntervalOption.Monthly: {
          setDatesAdjustedToBucketSize(
            dayjs().subtract(29, 'days'),
            dayjs(),
            BucketSizeOption.Daily,
          );
          break;
        }
        case PresetIntervalOption.Weekly: {
          setDatesAdjustedToBucketSize(
            dayjs().subtract(6, 'days'),
            dayjs(),
            BucketSizeOption.Daily,
          );
          break;
        }
        default:
          return;
      }
    },
    [setDatesAdjustedToBucketSize],
  );

  return (
    <Col>
      <Row gutter={32} className="items-center w-full">
        <Col>
          <Row className="mb-1 text-grayDark font-semibold">
            {t('components.InsightsFilters.interval')}
          </Row>
        </Col>
        <Col>
          <Row gutter={16} className="items-center" w-fill>
            <Col>
              <Radio.Group
                options={presetIntervalOptions}
                onChange={onIntervalChange}
                value={interval}
                optionType="button"
              />
            </Col>
            <Col>
              <DatePicker.RangePicker
                picker={datePickerMode}
                value={[startDate, endDate]}
                onChange={(values) => {
                  datePickerOnChange([values?.[0], values?.[1]]);
                }}
              />
            </Col>
            <Col>
              <BucketSizePicker setInterval={setInterval} />
            </Col>
            <Col>
              <Row className="mb-1 mt-2 text-grayDark font-semibold">
                {t('common.market')}
              </Row>
              <Row gutter={16} className="items-center">
                <Col>
                  <Select
                    value={market?.id}
                    onChange={(e) =>
                      setMarket(markets?.find((m) => m.id === e))
                    }
                    loading={loadingMarkets}
                    popupMatchSelectWidth={false}>
                    {markets?.map((m) => (
                      <Select.Option key={m.id} value={m.id}>
                        {m.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  <Button
                    type={highlightFilterButton ? 'primary' : 'default'}
                    ghost={highlightFilterButton}
                    icon={<FilterOutlined />}
                    onClick={toggleShowExtendedFilters}>
                    {t('components.InsightsFilters.filter', {
                      count: numberOfFiltersSelected,
                    })}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default InsightsFilters;
