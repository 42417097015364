import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Edit from '../../resources/img/edit.svg';
import Remove from '../../resources/img/remove.svg';
import Swap from '../../resources/img/swap.svg';
import { BookingOrderStatus } from '../types/BookingOrder';
import ClientCompany from '../types/ClientCompany';
import FieldSelect from '../../components/FieldSelect';

import InformationCard from './InformationCard';
import BookingOrderStatusBadge from './BookingOrderStatusBadge';
import CreateClientCompanyModal from './CreateClientCompanyModal';
import EditClientCompanyModal from './EditClientCompanyModal';
import BookingOrderInvoicedBadge from './BookingOrderInvoicedBadge';
import BookingOrderFromLimeBadge from './BookingOrderFromLimeBadge';
import BookingOrderFromSelfServiceBadge from './BookingOrderFromSelfServiceBadge';
import { Button, Tooltip } from 'antd';
import { CheckOutlined, FileSyncOutlined } from '@ant-design/icons';
import { MapCompanyModal } from './MapCompanyModal';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

type ClientCompanyFieldProps = {
  bookingOrderId?: number;
  selectedClientCompany?: ClientCompany;
  selectedSubsidiaryCompany?: SubsidiaryCompany;
  setSelectedClientCompany?: (clientCompany?: ClientCompany) => void;
  onClientCompanyMappedToElsa?: (clientCompany: ClientCompany) => void;
  clientCompanies?: ClientCompany[];
  fetchClientCompanies?: () => void;
  bookingOrderStatus?: BookingOrderStatus;
  invoicedAt?: string;
  limeObjectId?: number | null;
  isSelfService?: boolean | null;
  setDeleteClientCompanyOnSave?: (clientCompany: number) => void;
  disableDelete?: boolean;
  loading?: boolean;
  showElsaMappingInformation: boolean;
  bookingHasElsaMappedCourseInstance: boolean;
};

const ClientCompanyField: React.FC<ClientCompanyFieldProps> = ({
  bookingOrderId,
  selectedClientCompany,
  selectedSubsidiaryCompany,
  setSelectedClientCompany,
  onClientCompanyMappedToElsa,
  clientCompanies,
  fetchClientCompanies,
  bookingOrderStatus,
  invoicedAt,
  limeObjectId,
  isSelfService,
  setDeleteClientCompanyOnSave,
  disableDelete,
  loading,
  showElsaMappingInformation,
  bookingHasElsaMappedCourseInstance,
}) => {
  const [showCreateClientCompanyModal, setShowCreateClientCompanyModal] =
    useState<boolean>(false);
  const [showEditClientCompanyModal, setShowEditClientCompanyModal] =
    useState<boolean>(false);
  const [showMapCompanyModal, setShowMapCompanyModal] =
    useState<boolean>(false);
  const [fieldSelectDefaultOpen, setFieldSelectDefaultOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const [currentUser] = useUser();

  const actionPopoverElements = useMemo(
    () =>
      fetchClientCompanies && setSelectedClientCompany
        ? [
            {
              rowElement: (
                <>
                  <img src={Swap} className="pr-2" />
                  {t('common.switch')}
                </>
              ),
              action: () => {
                setFieldSelectDefaultOpen(true);
                fetchClientCompanies();
                setSelectedClientCompany(undefined);
              },
              hidden: !setSelectedClientCompany,
              disabled: bookingHasElsaMappedCourseInstance,
              tooltip: bookingHasElsaMappedCourseInstance
                ? t(
                    'components.ClientCompanyField.disabledBecauseOfSyncedCourseInstances',
                  )
                : undefined,
            },
            {
              rowElement: (
                <>
                  <img src={Edit} className="pr-2" />
                  {t('common.edit')}
                </>
              ),
              action: () => setShowEditClientCompanyModal(true),
            },
            {
              rowElement: (
                <>
                  <img src={Remove} className="pr-2" />
                  {t('common.remove')}
                </>
              ),
              action: () => {
                setFieldSelectDefaultOpen(false);
                fetchClientCompanies();
                setSelectedClientCompany(undefined);
              },
              hidden: !setSelectedClientCompany,
              disabled: bookingHasElsaMappedCourseInstance,
              tooltip: bookingHasElsaMappedCourseInstance
                ? t(
                    'components.ClientCompanyField.disabledBecauseOfSyncedCourseInstances',
                  )
                : undefined,
            },
          ]
        : undefined,
    [
      fetchClientCompanies,
      setSelectedClientCompany,
      bookingHasElsaMappedCourseInstance,
      t,
    ],
  );

  const navigateToCustomerInElsa = useCallback(() => {
    const elsaAdminBaseUrl = process.env.REACT_APP_ELSA_ADMIN_BASE_URL;
    const elsaSubsidiaryCompanyId =
      selectedClientCompany?.subsidiaryCompany.elsaSubsidiaryCompanyId;
    const elsaClientCompanyId = selectedClientCompany?.elsaClientCompanyId;

    if (elsaAdminBaseUrl && elsaSubsidiaryCompanyId && elsaClientCompanyId) {
      window.open(
        `${elsaAdminBaseUrl}/subsidiaries/${elsaSubsidiaryCompanyId}/customers/${elsaClientCompanyId}/users`,
        '_blank',
      );
    }
  }, [
    selectedClientCompany?.elsaClientCompanyId,
    selectedClientCompany?.subsidiaryCompany.elsaSubsidiaryCompanyId,
  ]);

  const MappingInformation = useCallback(
    (elsaClientCompanyId?: string) => {
      if (elsaClientCompanyId) {
        return (
          <Tooltip title={t('components.ClientCompanyField.openInElsaTooltip')}>
            <div
              className="flex flex-row items-center gap-1 text-safeLifeSuccess hover:underline cursor-pointer"
              onClick={navigateToCustomerInElsa}>
              <CheckOutlined />
              <span className="text-md">
                {t('components.ClientCompanyField.mappedInElsa')}
              </span>
            </div>
          </Tooltip>
        );
      } else {
        const missingPermissions = !userHasPermission(
          currentUser,
          Permission.CLIENT_COMPANY_UPDATE,
        );
        return (
          <Tooltip
            title={
              missingPermissions
                ? t('components.ClientCompanyField.missingMappingPermission')
                : ''
            }>
            <Button
              type="text"
              disabled={missingPermissions}
              onClick={() => setShowMapCompanyModal(true)}
              className="text-white bg-safeLifeMedium disabled:text-gray-500 disabled:bg-gray-300">
              <FileSyncOutlined />
              {t('components.ClientCompanyField.mapToElsa')}
            </Button>
          </Tooltip>
        );
      }
    },
    [t, currentUser, navigateToCustomerInElsa],
  );

  const closeMapCompanyModal = useCallback(() => {
    setShowMapCompanyModal(false);
  }, []);

  return (
    <>
      <div className="pl-2 pb-1 font-medium text-blueGrayDark">
        {t('common.company')}
      </div>
      <div className="h-8">
        {selectedClientCompany ? (
          <div className="flex flex-row items-center space-x-2">
            <InformationCard actionPopoverElements={actionPopoverElements}>
              <div className="text-2xl font-semibold flex flex-row pr-4 h-6 leading-none">
                {selectedClientCompany.name}
              </div>
            </InformationCard>
            {showElsaMappingInformation &&
              MappingInformation(selectedClientCompany.elsaClientCompanyId)}
            <BookingOrderStatusBadge status={bookingOrderStatus} />
            {invoicedAt && <BookingOrderInvoicedBadge />}
            {limeObjectId && <BookingOrderFromLimeBadge />}
            {isSelfService && <BookingOrderFromSelfServiceBadge />}
          </div>
        ) : (
          <FieldSelect
            defaultOpen={fieldSelectDefaultOpen}
            className="w-64 pl-2"
            placeholderText={t('components.ClientCompanyField.pickCompany')}
            createButtonText={t(
              'components.ClientCompanyField.createNewCompany',
            )}
            loading={loading}
            elements={clientCompanies?.map((clientCompany) => ({
              primaryText: clientCompany.name,
              id: clientCompany.id,
            }))}
            onCreate={() => setShowCreateClientCompanyModal(true)}
            onSelect={(selectedId) =>
              setSelectedClientCompany?.(
                clientCompanies?.find((client) => client.id === selectedId),
              )
            }
          />
        )}
      </div>
      {selectedSubsidiaryCompany && (
        <CreateClientCompanyModal
          visible={showCreateClientCompanyModal}
          subsidiaryCompany={selectedSubsidiaryCompany}
          onCancel={() => setShowCreateClientCompanyModal(false)}
          onCreate={(clientCompany) => {
            setSelectedClientCompany?.(clientCompany);
            setShowCreateClientCompanyModal(false);
          }}
        />
      )}
      {selectedClientCompany && (
        <EditClientCompanyModal
          bookingOrderId={bookingOrderId}
          visible={showEditClientCompanyModal}
          disableDelete={disableDelete}
          clientCompany={selectedClientCompany}
          onUpdate={(clientCompany) => {
            setSelectedClientCompany?.(clientCompany);
            setShowEditClientCompanyModal(false);
          }}
          onDelete={() => {
            setDeleteClientCompanyOnSave?.(selectedClientCompany.id);
            setSelectedClientCompany?.(undefined);
            setShowEditClientCompanyModal(false);
          }}
          onCancel={() => setShowEditClientCompanyModal(false)}
        />
      )}
      {showMapCompanyModal &&
        selectedClientCompany &&
        selectedSubsidiaryCompany && (
          <MapCompanyModal
            clientCompany={selectedClientCompany}
            close={closeMapCompanyModal}
            onUpdate={(cc: ClientCompany) => {
              onClientCompanyMappedToElsa?.(cc);
            }}
          />
        )}
    </>
  );
};

export default ClientCompanyField;
