import { chunk } from 'lodash';
import { CourseInstanceStatus } from '../types/CourseInstanceStatus';
import { CourseInstanceLeadTime } from '../types/CourseInstanceLeadTime';
import dayjs, { Dayjs } from 'dayjs';
import { BucketSizeOption } from '../types/InsightsFilters';
import { ComputedDatum } from '@nivo/line';

const monthOfYearFormat = 'MMM YYYY';
const dayOfMonthFormat = 'D MMM';

export const bucketGraphData = (
  graphs: {
    id: CourseInstanceStatus | CourseInstanceLeadTime;
    label: string;
    data: { x: Date; y: number }[];
    color: string;
  }[],
  statusBucketSize: BucketSizeOption,
) => {
  return graphs.map((graph) => {
    switch (statusBucketSize) {
      case BucketSizeOption.Monthly:
        return {
          ...graph,
          data: groupByMonth(graph.data),
        };

      case BucketSizeOption.Weekly:
        return {
          ...graph,
          data: groupByWeek(graph.data),
        };
      case BucketSizeOption.Daily:
        return { ...graph, data: graph.data };
    }
  });
};

const groupByWeek = (
  data: { x: Date; y: number }[],
): { x: Date; y: number }[] => {
  return chunk(data, 7).map((d) => ({
    x: d[0].x,
    y: d.reduce((prev, curr) => prev + curr.y, 0),
  }));
};

const groupByMonth = (
  data: { x: Date; y: number }[],
): { x: Date; y: number }[] => {
  const grouped = Object.groupBy(data, (data) =>
    dayjs(data.x).format('YYYY-MM'),
  );
  const groupedValues = Object.values(grouped).filter((g) => g !== undefined);
  const sortedByMonth = groupedValues.sort(
    (a, b) => a[0].x.getTime() - b[0].x.getTime(),
  );
  const summedMonths = sortedByMonth.map((gv) =>
    gv?.reduce(
      (acc, point) => {
        return { x: gv[0].x, y: acc.y + point.y };
      },
      { x: gv[0].x, y: 0 },
    ),
  );

  return summedMonths;
};

export const updateBucketSize = (
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  setBucketSize: (bucketSize: number) => void,
) => {
  const diffInDays = endDate.diff(startDate) / (1000 * 60 * 60 * 24);

  if (diffInDays <= 14) {
    setBucketSize(BucketSizeOption.Daily);
  } else if (diffInDays <= 93) {
    setBucketSize(BucketSizeOption.Weekly);
  } else {
    setBucketSize(BucketSizeOption.Monthly);
  }
};

export const insertBetween = (
  arr: readonly ComputedDatum[],
  valuesToInsert: (a: ComputedDatum, b: ComputedDatum) => ComputedDatum[],
): ComputedDatum[] => {
  const result: ComputedDatum[] = [];

  for (let i = 0; i < arr.length - 1; i++) {
    result.push(arr[i]);
    result.push(...valuesToInsert(arr[i], arr[i + 1]));
  }

  // Add the last element of the array since it won't be included in the loop
  if (arr.length > 0) {
    result.push(arr[arr.length - 1]);
  }

  return result;
};

export const getBucketTooltipStartDate = (
  date: Dayjs,
  bucketSize: BucketSizeOption,
) => {
  switch (bucketSize) {
    case BucketSizeOption.Monthly:
      return date.format(monthOfYearFormat);
    case BucketSizeOption.Weekly:
      return date.startOf('week').format(dayOfMonthFormat);
    case BucketSizeOption.Daily:
      return date.format(dayOfMonthFormat);
  }
};

export const getBucketTooltipEndDate = (
  date: Dayjs,
  graphEndDate: Dayjs,
  bucketSize: BucketSizeOption,
) => {
  switch (bucketSize) {
    case BucketSizeOption.Daily:
    case BucketSizeOption.Monthly:
      return undefined;
    case BucketSizeOption.Weekly:
      const endDate = graphEndDate.isBefore(date.endOf('week'))
        ? graphEndDate
        : date.endOf('week');
      return endDate.format(dayOfMonthFormat);
  }
};
