export enum PresetIntervalOption {
  Yearly = 'yearly',
  Quaterly = 'quaterly',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export enum BucketSizeOption {
  Monthly,
  Weekly,
  Daily,
}
