import i18next from 'i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import { LocaleSingularArg } from '@fullcalendar/core';
import svLocale from '@fullcalendar/core/locales/sv';
import enGbLocale from '@fullcalendar/core/locales/en-gb';
import svSE from 'antd/es/locale/sv_SE';
import enGB from 'antd/es/locale/en_GB';

import Language from '../types/Language';
import client from '../api/client';

import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

export const changeLanguage = (
  language: Language | undefined,
  setLanguage: (language: Language | undefined) => void,
) => {
  setLanguage(language);
  // eslint-disable-next-line import/no-named-as-default-member
  i18next.changeLanguage(language?.code);

  dayjs.updateLocale(language?.code ?? i18next.language, {
    weekStart: 1,
  });

  dayjs.locale(language?.code);
  client.defaults.headers.common['Accept-Language'] =
    language?.code ?? i18next.language;
};

export const getFullCalendarLocale = (code?: string) => {
  switch (code) {
    case 'sv':
      return svLocale as LocaleSingularArg;
    case 'en':
      return enGbLocale as LocaleSingularArg;
    default:
      return enGbLocale as LocaleSingularArg;
  }
};

export const getAntdLocale = (code?: string) => {
  switch (code) {
    case 'sv':
      return svSE;
    case 'en':
      return enGB;
    default:
      return enGB;
  }
};
