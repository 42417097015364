import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  BucketSizeOption,
  PresetIntervalOption,
} from '../types/InsightsFilters';
import { useInsights } from '../../context/insights';

type BucketSizePickerProps = {
  setInterval: (value: PresetIntervalOption | undefined) => void;
  className?: string;
};

const BucketSizePicker: React.FC<BucketSizePickerProps> = ({
  setInterval,
  className,
}) => {
  const { t } = useTranslation();

  const { startDate, endDate, setDatesAdjustedToBucketSize, statusBucketSize } =
    useInsights();

  const bucketSizeOptions = [
    {
      value: BucketSizeOption.Monthly,
      label: t('components.InsightsFilters.BucketSizeOptions.monthly'),
    },
    {
      value: BucketSizeOption.Weekly,
      label: t('components.InsightsFilters.BucketSizeOptions.weekly'),
    },
    {
      value: BucketSizeOption.Daily,
      label: t('components.InsightsFilters.BucketSizeOptions.daily'),
    },
  ];

  return (
    <Radio.Group
      className={className}
      options={bucketSizeOptions}
      value={statusBucketSize}
      onChange={({ target: { value } }: RadioChangeEvent) => {
        setInterval(undefined);
        setDatesAdjustedToBucketSize(startDate, endDate, value);
      }}
      optionType="button"
    />
  );
};

export default BucketSizePicker;
